$icon-font-path: "../node_modules/bootstrap-sass/assets/fonts/bootstrap";
@import "../node_modules/bootstrap/scss/bootstrap";
// $fa-font-path : "../node_modules/font-awesome/fonts";
// @import "../node_modules/font-awesome/scss/font-awesome";
@import "../node_modules/font-awesome/css/font-awesome.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@font-face {
    font-family: 'MoonHouse';
    src: url('./assets/fonts/moonhouse.eot');
    src: url('./assets/fonts/moonhouse.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/moonhouse.ttf') format('truetype');
}

@import "app/theme/styles/variables";
@import "app/theme/styles/mixins";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/base";


@-webkit-keyframes trans1{
  from {
    transform : translate3d(53px,0,0);
  }
  to {

    transform : translate3d(-250px,0,0);
  }
}
@-moz-keyframes trans1{
  from {
    transform : translate3d(53px,0,0);
  }
  to {
    transform : translate3d(-250px,0,0);
  }
}
@keyframes trans1{
  from {
    transform : translate3d(53px,0,0);
  }
  to {
    transform : translate3d(-250px,0,0);
  }
}

@-webkit-keyframes trans2{
  from {
    transform : translate3d(-160px,0,0);
  }
  to {
    transform : translate3d(53px,0,0);
  }
}
@-moz-keyframes trans2{
  from {
    transform : translate3d(-160px,0,0);
  }
  to {
    transform : translate3d(53px,0,0);
  }
}
@keyframes trans2{
  from {
    transform : translate3d(-160px,0,0);
  }
  to {
    transform : translate3d(53px,0,0);
  }
}

@-webkit-keyframes trans3{
  from {
    transform : translate3d(53px,0,0);
  }
  to {
    transform : translate3d(-220px,0,0);
  }
}
@-moz-keyframes trans3{
  from {
    transform : translate3d(53px,0,0);
  }
  to {
    transform : translate3d(-220px,0,0);
  }
}
@keyframes trans3{
  from {
    transform : translate3d(53px,0,0);
  }
  to {
    transform : translate3d(-220px,0,0);
  }
}

.loader-outer {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 9999;
    background: #fff;
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility 0.5s, opacity 0.3s linear;
    -moz-transition: visibility 0.5s, opacity 0.3s linear;
    transition: visibility 0.5s, opacity 0.3s linear;
    &.hide{
        visibility: hidden;
        opacity: 0;
    }
    .loader-container {
        height:100%;
        width:100%;
        display:table;
        .loader-inner {
            vertical-align:middle;
            height:100%;
            display:table-cell;
            .loader{
                width:80px;
                margin:auto;
                position:relative;
                height:60px;
                .mask{
                    position:absolute;
                    overflow:hidden;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    border-radius: 5px;
                    .plane{
                        background:#e17d0b;
                        width:400%;
                        height:100%;
                        position:absolute;
                    }
                }
                #top{
                    width:53px;
                    height:15px;
                    left:20px;
                    -webkit-transform: skew(-15deg, 0);
                    -moz-transform: skew(-15deg, 0);
                    transform: skew(-15deg, 0);
                    .plane{
                        -webkit-animation : trans1 1.3s ease-in infinite  0s backwards;
                        -moz-animation : trans1 1.3s ease-in infinite  0s backwards;
                        animation : trans1 1.3s ease-in infinite  0s backwards;
                    }
                }
                #middle{
                    width:33px;
                    height:15px;
                    left:20px;
                    top:15px;
                    -webkit-transform: skew(-15deg, 40deg);
                    -moz-transform: skew(-15deg, 40deg);
                    transform: skew(-15deg, 40deg);
                    .plane{
                        transform : translate3d(0px,0,0);
                        background : #DD1B16;
                        -webkit-animation : trans2 1.3s linear infinite  0.3s  backwards;
                        -moz-animation : trans2 1.3s linear infinite  0.3s  backwards;
                        animation : trans2 1.3s linear infinite  0.3s  backwards;
                    }
                }
                #bottom{
                    width:53px;
                    height:15px;
                    top:30px;
                    -webkit-transform: skew(-15deg, 0);
                    -moz-transform: skew(-15deg, 0);
                    transform: skew(-15deg, 0);
                    .plane{
                        -webkit-animation : trans3 1.3s ease-out infinite  0.7s backwards;
                        -moz-animation : trans3 1.3s ease-out infinite  0.7s backwards;
                        animation : trans3 1.3s ease-out infinite  0.7s backwards;
                    }
                }
                p.loader-text{
                    color:rgb(61, 133, 214);
                    position:absolute;
                    top:42px;
                    font-family: 'MoonHouse';
                    font-size:16px;
                    span{
                        color: #dc9907;
                    }
                }
            }
        }
    }
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 0px;
    &:hover{
        background: #666666;
    }
    &:active{
        background: #888888;
    }
}
::-webkit-scrollbar-track {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 0px;
    &:hover{
        background: #E1E1E1;
    }
    // &:active{
    //     background: #333333;
    // }
}
::-webkit-scrollbar-corner {
    background: transparent;
}
.login-bg {
  background: linear-gradient(rgba(21, 18, 18, 0.5), rgba(25, 17, 17, 0.5)),
    url("./../src/assets/img/vendor/leaflet/imgpsh_fullsize_anim.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}



